<template>
  <div class="box">
    <homes ref="productImage"></homes>
    <div class="content">
      <div class="header">
        <div class="head">
          <h3 class="title">华为云上链信息查询</h3>
          <div class="search-input" style="margin-top: 15px">
            <el-select
              class="select"
              v-model="selectType"
              placeholder="请选择活动区域"
            >
              <el-option
                v-for="(items, idx) in selectList"
                :key="idx"
                :label="items.name"
                :value="items.value"
              ></el-option>
            </el-select>
            <el-input
              class="input-search"
              v-model="searchValue"
              placeholder="请输入您华为链ID进行查询"
            ></el-input>
            <el-button
              class="btn-search"
              type="danger"
              icon="el-icon-search"
              @click="search"
              >搜索</el-button
            >
          </div>
        </div>
      </div>
      <div class="nav-list" v-if="chainList.length > 0">
        <div class="info-data" v-for="(items, idx) in chainList" :key="idx">
          <div class="time">
            <div style="width: 91px;">{{ items.Timestamp }}</div>
            <div v-if="idx !== chainList.length - 1" class="solid">
              <img
                class="dian"
                style="width: 10px; height: 10px"
                src="@/assets/img/dian-img.png"
                alt=""
              />
            </div>
          </div>
          <div class="info">
            <img class="info-img" :src="items.avatar ? $imgUrl(items.avatar) : srcImgUrl" :onerror="$global.srcImgUrl" alt="" />
            <div class="center">
              <p class="name">{{ items.Values.name ? $processName(items.Values.name) : '' }}</p>
              <p class="user-info">
                {{ items.Values.age }}岁<el-divider direction="vertical"></el-divider>{{ $occlusionCard(items.Values.idCard) }}
              </p>
              <!-- <p class="poster">职位 : 施工员</p> -->
              <div class="grade">
                <span class="span-title">信用分 :</span>
                <p
                  class="grade-num"
                  v-if="items.Values.grade >= 80"
                  :style="{
                    color: gradeColor[0],
                    borderColor: gradeColor[0],
                  }"
                >
                  {{ Number(items.Values.grade).toFixed(1) }}
                </p>
                <p
                  class="grade-num"
                  v-if="items.Values.grade < 80 && items.Values.grade >= 60"
                  :style="{
                    color: gradeColor[1],
                    borderColor: gradeColor[1],
                  }"
                >
                  {{ Number(items.Values.grade).toFixed(1) }}
                </p>
                <p
                  class="grade-num"
                  v-if="items.Values.grade < 60 && items.Values.grade >= 40"
                  :style="{
                    color: gradeColor[2],
                    borderColor: gradeColor[2],
                  }"
                >
                  {{ Number(items.Values.grade).toFixed(1) }}
                </p>
                <p
                  class="grade-num"
                  v-if="items.Values.grade < 40"
                  :style="{
                    color: gradeColor[3],
                    borderColor: gradeColor[3],
                  }"
                >
                  {{ Number(items.Values.grade).toFixed(1) }}
                </p>
              </div>
            </div>
            <div class="right">
              <div v-for="(item, index) in items.data" :key="index" class="up-info">
                <div class="left">{{ item.name }}</div>
                <div class="right-text">{{ item.info }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
    <footers class="footer"></footers>
  </div>
</template>

<script>
import homes from "../../components/hometitle/hometitle.vue";
import footers from "../../components/foot/foot.vue";
import { getBCSGradeByIdCard } from "@/api/evaluation/evaluation";
export default {
  components: {
    footers,
    homes,
  },
  data() {
    return {
      srcImgUrl: require("../../assets/home/people.png"),
      gradeColor: ["#67CF61", "#3B94F7", "#e6a23c", "#ff0000"],
      dict: [],
      selectType: "1",
      searchValue: "",
      chainList: [],
      selectList: [
        { name: "个人", value: "1" },
        { name: "劳务", value: "2" },
        { name: "班组", value: "3" },
      ],
    };
  },
  created() {
    this.dict = JSON.parse(localStorage.getItem("dict"));
  },
  methods: {
    search() {
        this.getList(this.searchValue);
    },
    getList(id) {
      getBCSGradeByIdCard({ idCard: id, userType: this.selectType }).then(
        (res) => {
          console.log(res, "信息");
          if (res.data) {
            //  setTimeout(() => {
            res.data.map((item) => {
              item.Values = JSON.parse(item.Value);
              item.Timestamp = this.timestampToTime(
                item.Timestamp.slice(0, 10)
              );
              item.BlockNum = item.BlockNum + 1;
              item.data = [
                {
                  name: "区块高度",
                  info: "",
                },
                {
                  name: "上链时间",
                  info: "",
                },
                {
                  name: "上链ID",
                  info: "",
                },
                {
                  name: "上链状态",
                  info: "",
                },
              ];
              item.data[0].info = item.BlockNum;
              item.data[1].info = item.Timestamp;
              item.data[2].info = item.TxHash;
              item.data[3].info = item.IsDeleted ? "error" : "Success";
            });
            this.chainList = res.data;
            // }, 600);
          } else {
            this.chainList = [];
          }
          console.log(this.chainList, "信息");
        }
      );
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes();
      var s = date.getSeconds();
      return Y + M + D + h + m;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  font-size: 16px;
  .header {
    margin-top: 80px;
    margin-bottom: 50px;
    width: 100%;
    height: 26vh;
    background-image: url("~@/assets/img/cochain-img.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .head {
      position: absolute;
      top: 20px;
      left: 20%;
      .title {
        font-size: 36px;
      }
      .search-input {
        display: flex;
        align-items: center;
        position: relative;
        .input-search {
          width: 501px;
          height: 48px;
        }
        .btn-search {
          width: 171px;
          height: 48px;
          font-size: 18px;
          border-radius: 10px;
          margin-left: -20px;
          z-index: 1;
        }
      }
    }
  }
  .nav-list {
    width: 73%;
    margin: 0 auto;
    margin-bottom: 50px;
    .info-data {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .time {
        text-align: center;
        margin-right: 20px;
        position: relative;
      }
      .info {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        padding: 27px 29px;
        background-color: #fff;
        .info-img {
          width: 160px;
          height: 160px;
        }
        .center {
          width: 200px;
          margin: 0 30px 0 32px;
          .name {
            font-size: 18px;
            font-weight: bold;
          }
          .user-info,
          .poster {
            font-size: 14px;
            color: #666666;
          }
          .grade {
            display: flex;
            align-items: center;
            .span-title {
              font-size: 16px;
              color: #666666;
              font-weight: bold;
              margin-right: 5px;
            }
            .grade-num {
              font-size: 20px;
              font-family: buer;
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    width: 550px;
    // padding: 15px 0;
    margin-left: 40px;
    .title-name {
      padding: 10px 0;
      font-size: 18px;
      font-weight: bold;
    }
    .up-info {
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-sizing: border-box;
      flex-wrap: wrap;
      padding: 10px;
      margin: 7px 0;

      .left {
        width: 20%;
        font-size: 16px;
        color: #666666;
      }
      .right-text {
        flex: 1;
        width: 50%;
        word-break:break-all;
        font-size: 16px;
      }
    }
  }
}
.input-search ::v-deep .el-input__inner {
  height: 48px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
  padding-left: 88px;
  padding-right: 25px;
}
.input-search ::v-deep .el-input__inner:focus {
  border: 1px solid #c7000b;
}
::v-deep .el-button--danger:active {
  background-color: #ec6772;
  border: #c6020c;
}
::v-deep .el-button--danger {
  background-color: #c7000b;
  border: #c7000b;
}
.solid {
  position: absolute;
  left: 48%;
  top: 72px;
  width: 3px;
  height: 200px;
  background-image: linear-gradient(
    to bottom,
    rgba(199, 0, 11, 0.3) 0%,
    rgba(199, 0, 11, 0.3) 50%,
    transparent 50%
  );
  background-size: 2px 20px;
  background-repeat: repeat-y;
  .dian {
    position: absolute;
    top: -24px;
    left: -4px;
  }
}
.select {
  width: 83px;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 10;
  // left: -80px;
  border: none;
}
.select ::v-deep .el-input__inner {
  height: 44px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
}
</style>
